@import "../../../assets/styles/variables";

.footer-client {
  background-color: $primary-color;
  color: white;
  text-align: center;
  padding: 1rem;
  width: 100%;

  @media (min-width: 769px) {
    position: fixed;
    bottom: 0;
  }

  @media (max-width: 768px) {
    position: relative;
    bottom: auto;
  }
}
