// HomePage.scss
.home-page {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;

  .config-section {
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
  }

  .preview-section {
    position: relative;
    // padding: 20px;
    padding: 15px 70px 85px 70px;
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;

    &.full {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 40px;
      z-index: 1000;
      background-color: rgba(255, 255, 255, 0.9);
      overflow-y: auto;
    }

    .preview-toggle-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 10px 20px;
      background-color: #007bff;
      color: #ffffff;
      border: none;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}
