@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import './clientVariables';

@function hex-to-rgba($color, $alpha) {
  @return rgba(red($color), green($color), blue($color), $alpha);
}

$primary-color: $btn-background-color;
$on-primary-color: $btn-text-color;
$secondary-color: $header-btn-background-color;
$on-secondary-color: $header-btn-text-color;

$font-size-base: 1rem;
$font-size-large: 1.5rem;
$font-size-small: 0.875rem;
$headings-line-height: 1;
$headings-font-weight: 500;

$white: #ffffff;
$info: #0a8dd0;
$info-color-dark: #097db9;
$info-color-light: #7ccef9;
$info-color-background: #e2f5ff;
$success: #22a121;
$success-color-dark: #1d8c1d;
$success-color-light: #78e378;
$success-color-background: #d7fdd7;
$warning: #fda018;
$warning-color-dark: #ea8b00;
$warning-color-light: #fec97c;
$warning-color-background: #ffecd1;
$danger: #d8201d;
$danger-color-dark: #c21d1a;
$danger-color-light: #f29e9c;
$danger-color-background: #ffebeb;

$gray-100: #f2f3f7;
$gray-200: #cfd5dc;
$gray-300: #cfd5dc;
$gray-400: #99a2b1;
$gray-500: #656f85;
$gray-600: #43546a;
$gray-700: #344055;
$gray-800: #192232;
$gray-900: #192232;
$body-color: $text-color;

:root {
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --toastify-color-info: $info;
  --toastify-color-success: $success;
  --toastify-color-warning: $warning;
  --toastify-color-error: $danger;
}

$toastify-color-dark: $text-color;
$h1-font-size: 3rem;
$h2-font-size: 2.5rem;
$h4-font-size: 1.1rem;

$primary: $primary-color;
$secondary: $secondary-color;


//forms 
$input-placeholder-color: $gray-300;
$input-border-color: $gray-300;
$form-label-color: $body-color;

$btn-close-color: $body-color;