.main-wrapper {
  background: #f8f9fa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .header-trans.custom {
    background: transparent;
    transition: background 0.3s ease;
    &.scrolled {
      background: #2b6ccb;
    }
  }

  .content {
    flex: 1;
    padding: 20px;
    .container-fluid {
      .row {
        margin: 0;
        .theiaStickySidebar {
          .stickyside {
            background: #ffffff;
            padding: 20px;
            border: 1px solid #e3e3e3;
            border-radius: 5px;
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }

  .footer-user.footer-2.custom-footer {
    background: #2b6ccb;
    color: #ffffff;

    .footer-top {
      padding: 60px 0;
      .container-fluid {
        .row {
          .footer-widget {
            margin-bottom: 30px;

            &.footer-about {
              .footer-logo {
                margin-bottom: 15px;
                img {
                  max-width: 150px;
                }
              }
              .footer-about-content {
                p {
                  color: #e3e3e3;
                  font-size: 14px;
                }
              }
            }

            &.footer-menu {
              .footer-title {
                color: #ffffff;
                font-size: 18px;
                margin-bottom: 15px;
              }
              .footer-contact-info {
                .footer-address {
                  margin-bottom: 15px;
                  p {
                    color: #e3e3e3;
                    font-size: 14px;
                  }
                }
                p {
                  color: #e3e3e3;
                  font-size: 14px;
                }
              }
              .rrss {
                list-style: none;
                padding: 0;
                li {
                  display: inline-block;
                  &:last-child i {
                    margin-right: 0;
                  }
                  a {
                    margin-right: 16px;
                    transition: all ease 0.3s;
                    &:hover {
                      opacity: 0.5;
                    }
                    i {
                      font-size: 21px;
                      color: #ffffff;
                      margin-right: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .footer-bottom {
      background: #1f4e8c;
      padding: 20px 0;
      .container-fluid {
        .row {
          .copyright {
            .copyright-text {
              p {
                color: #ffffff;
                margin: 0;
                font-size: 14px;
                a {
                  color: #ffffff;
                  text-decoration: underline;
                  &:hover {
                    text-decoration: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
