.form-content {
  display: flex;
  align-items: flex-start;

  .image-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;

    .custom-file-upload {
      position: relative;
      display: inline-block;
      margin-bottom: 10px;

      .file-input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .image-preview {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid #ddd;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .form-section {
    flex: 2;
  }
}
