@import "../../../assets/styles/variables";

.services {
  text-align: center;

  h2 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 2rem;
    color: #666;
  }

  .service-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;

    .service-item {
      background-color: white;
      padding: 1rem;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      margin-bottom: 1rem;
      width: 80%;
      text-align: left;

      h4 {
        margin-bottom: 0.5rem;
      }

      p {
        margin: 0.25rem 0;
      }

      .edit,
      .remove {
        background: none;
        border: none;
        color: $primary-color;
        cursor: pointer;
        margin-right: 1rem;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .service-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

    input,
    textarea,
    select {
      margin: 0.5rem 0;
      padding: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 80%;
    }

    .btn-primary {
      background-color: $primary-color;
      border-color: $primary-color;
      font-weight: bold;
      border-radius: 10px;
      padding: 0.75rem 1.5rem;
      font-size: 1.25rem;
    }

    .btn-primary:hover {
      background-color: darken($primary-color, 10%);
      border-color: darken($primary-color, 10%);
    }
  }

  .add {
    background: none;
    border: none;
    color: $primary-color;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
    }
  }

  .btn-primary {
    background-color: $primary-color;
    border-color: $primary-color;
    font-weight: bold;
    border-radius: 10px;
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
  }

  .btn-primary:hover {
    background-color: darken($primary-color, 10%);
    border-color: darken($primary-color, 10%);
  }
}
