.payment-methods {
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  h3 {
    margin-bottom: 1.5rem;
    color: #333;
  }

  .btn {
    background-color: #28a745;
    border: none;
    color: white;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 1rem;

    &:hover {
      background-color: #218838;
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      background-color: #fff;
      padding: 1rem;
      border-radius: 4px;
      margin-bottom: 1rem;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:last-child {
        margin-bottom: 0;
      }

      .remove-button {
        background-color: #dc3545;
        border: none;
        color: white;
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #c82333;
        }
      }
    }
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
    background-color: #f8f9fa;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .close {
      padding: 0.5rem;
      margin: -0.5rem -0.5rem -0.5rem auto;
    }
  }

  .modal-body {
    padding: 2rem;

    .form-group {
      margin-bottom: 1.5rem;

      label {
        display: block;
        margin-bottom: 0.5rem;
        color: #495057;
      }

      input,
      select {
        width: 100%;
        padding: 0.5rem;
        font-size: 1rem;
        border-radius: 4px;
        border: 1px solid #ced4da;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
      }

      .form-control.is-invalid {
        border-color: #dc3545;
        padding-right: 2.25rem;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2' class='text-danger'%3e%3cpath stroke-linecap='round' stroke-linejoin='round' d='M18.364 5.636a9 9 0 11-12.728 0m0 0a9 9 0 0112.728 0m0 0L12 12m6.364-6.364L12 12m0 0l-6.364 6.364m0 0L12 12'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
      }

      .form-control-feedback {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #dc3545;
      }
    }

    .form-actions {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;

      .btn {
        &.btn-primary {
          background-color: #007bff;
          border: none;
          &:hover {
            background-color: #0069d9;
          }
        }

        &.btn-secondary {
          background-color: #6c757d;
          border: none;
          &:hover {
            background-color: #5a6268;
          }
        }
      }
    }
  }
}
