@import "../../../assets/styles/variables";

.onboarding {
  display: flex;
  height: 100vh;

  .sidebar {
    width: 25%;
    background-color: #f7fafc;
    padding: 2rem;

    .list-group-item {
      cursor: pointer;
      font-weight: bold;

      &.completed {
        background-color: $primary-color;
        color: white;
      }

      &.active {
        background-color: #e0f7fa;
        color: $primary-color;
      }
    }
  }

  .content {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;

    .form-container {
      width: 100%;
      max-width: 600px;
      padding: 2rem;
      background-color: white;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

      .form-title {
        text-align: justify;
        margin-bottom: 2rem;
      }

      .form-group {
        margin-bottom: 1.5rem;
      }

      label {
        font-weight: bold;
      }

      .btn-primary {
        background-color: $primary-color;
        border-color: $primary-color;
        font-weight: bold;
        border-radius: 10px;
        padding: 0.75rem 1.5rem;
        font-size: 1.25rem;
      }

      .btn-primary:hover {
        background-color: darken($primary-color, 10%);
        border-color: darken($primary-color, 10%);
      }
    }
  }
}
