@import "../../../../assets/styles/variables";

.appointments {
  .tabs {
    display: flex;
    border-bottom: 2px solid #ddd;
    margin-bottom: 1rem;

    .tab {
      padding: 5px 10px; // Reducir el padding
      cursor: pointer;
      border: none;
      background: none;
      outline: none;
      font-size: 0.875rem; // Reducir el tamaño de la fuente
      transition: background-color 0.3s, border-bottom 0.3s;
      margin-right: 5px; // Reducir el margen

      &.active {
        background-color: $primary-color;
        color: white;
        border-radius: 5px 5px 0 0;
        border-bottom: 2px solid white;
      }

      &:hover {
        background-color: lighten($primary-color, 10%);
      }
    }
  }

  .appointments-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;

    th,
    td {
      padding: 8px; // Reducir el padding
      border: 1px solid #ddd;
    }

    th {
      background-color: #f8f9fa;
      font-size: 0.875rem; // Reducir el tamaño de la fuente
    }

    td {
      font-size: 0.75rem; // Reducir el tamaño de la fuente

      .icon {
        margin-right: 4px; // Reducir el margen
        color: $primary-color;
      }
    }

    .btn-danger {
      background-color: $danger;
      color: #fff;
      padding: 4px 8px; // Reducir el padding
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: darken($danger, 10%);
      }
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;

    .btn-primary {
      background-color: $primary-color;
      color: white;
      border: none;
      padding: 4px 8px; // Reducir el padding
      cursor: pointer;
      border-radius: 4px;
      transition: background-color 0.3s;
      font-size: 0.875rem; // Reducir el tamaño de la fuente

      &:hover {
        background-color: darken($primary-color, 10%);
      }

      &:disabled {
        background-color: lighten($primary-color, 30%);
        cursor: not-allowed;
      }
    }

    span {
      margin: 0 10px;
      font-size: 0.875rem; // Reducir el tamaño de la fuente
    }
  }

  .btn-primary {
    background-color: $primary-color;
    color: white;
    border: none;
    padding: 8px 16px; // Reducir el padding
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
    font-size: 0.875rem; // Reducir el tamaño de la fuente

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }
}
