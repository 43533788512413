@import "../../../../assets/styles/variables";

.services {
  .add-service-button {
    background: none;
    border: none;
    color: $primary-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .fa-plus-circle {
      margin-right: 0.5rem;
    }
  }

  .service-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .service-card {
    border: 1px solid #ddd;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;

    img {
      width: 100%;
      height: auto;
      border-radius: 8px;
      margin-bottom: 1rem;
    }

    .edit-button {
      background: none;
      border: none;
      color: $primary-color;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-top: 1rem;

      .fa-edit {
        margin-right: 0.5rem;
      }
    }
  }

  .services-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;

    th,
    td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
    }

    th {
      background-color: #f2f2f2;
      color: #333;
    }

    tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    tr:hover {
      background-color: #f1f1f1;
    }

    .edit-button {
      background-color: #e74c3c;
      color: #fff;
      border: none;
      padding: 5px 10px;
      cursor: pointer;
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }

      &:hover {
        background-color: #c0392b;
      }
    }
  }
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.is-invalid {
  border-color: #dc3545;
}

.invalid-feedback {
  color: #dc3545;
  display: block;
  margin-top: 0.25rem;
}

.custom-modal {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  max-width: 500px;
  margin: auto;
  width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);

  .form-group {
    margin-bottom: 15px;

    label {
      display: block;
      margin-bottom: 5px;
    }

    input,
    textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }

    .invalid-feedback {
      color: red;
      font-size: 12px;
    }
  }
}

.radio-group {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;

  .radio-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;

  .btn {
    margin-left: 0.5rem;
  }
}
