.card {
  border: 1px solid #e3e3e3;
  margin-bottom: 30px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  .card-body {
    padding: 15px;

    .doctor-widget {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .doc-info-left {
        display: flex;

        .doctor-img {
          margin-right: 15px;
          img {
            border-radius: 50%;
            width: 130px;
            height: 85px;
          }
        }

        .doc-info-cont {
          h4 {
            margin: 0;
            font-size: 18px;
            font-weight: 600;
            a {
              color: #2467ca;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          .doc-speciality {
            font-size: 14px;
            color: #666;
            margin-bottom: 5px;
          }
          .doc-department {
            font-size: 14px;
            color: #666;
            img {
              margin-right: 5px;
            }
          }
          .rating {
            display: flex;
            align-items: center;
            i {
              color: #f1c40f;
              margin-right: 5px;
            }
            .average-rating {
              margin-left: 5px;
              font-size: 14px;
              color: #666;
            }
          }
          .clinic-details {
            .doc-location {
              font-size: 14px;
              color: #666;
              i {
                margin-right: 5px;
              }
            }
            .clinic-gallery {
              display: flex;
              li {
                margin-right: 20px !important;
                a {
                  img {
                    width: 50px;
                    height: 50px;
                    border-radius: 4px;
                  }
                }
              }
            }
          }
          .clinic-services {
            span {
              display: inline-block;
              background-color: #e3e3e3;
              padding: 5px 10px;
              border-radius: 4px;
              margin-right: 5px;
              font-size: 12px;
            }
          }
        }
      }

      .doc-info-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .clini-infos {
          ul {
            padding: 0;
            list-style: none;
            margin-bottom: 10px;
            li {
              font-size: 14px;
              color: #666;
              display: flex;
              align-items: center;
              i {
                margin-right: 5px;
              }
            }
          }
        }
        .clinic-booking {
          display: flex;
          flex-direction: column;
          a {
            background-color: #2467ca;
            color: white;
            padding: 10px 15px;
            border-radius: 4px;
            text-align: center;
            margin-bottom: 5px;
            &:hover {
              background-color: darken(#2467ca, 10%);
            }
            &.apt-btn {
              background-color: #28a745;
              &:hover {
                background-color: darken(#28a745, 10%);
              }
            }
          }
        }
      }
    }
  }
}
