@import "../../../../assets/styles/variables";
// .schedule {
//   .schedule-title {
//     font-size: 1.5rem;
//     margin-bottom: 1rem;
//   }

//   .service-selector {
//     margin-bottom: 1.5rem;
//   }

//   .sub-title {
//     font-size: 1.25rem;
//     margin-bottom: 1rem;
//   }

//   .days-of-week {
//     display: flex;
//     gap: 0.5rem;
//     margin-bottom: 1rem;
//     justify-content: center;

//     .day-button {
//       padding: 0.5rem 1rem;
//       border: none;
//       background-color: #f1f1f1;
//       cursor: pointer;

//       &.active {
//         background-color: $primary-color;
//         color: white;
//       }
//     }
//   }

//   .hours-block {
//     .hours-title {
//       font-size: 1.25rem;
//       margin-bottom: 0.5rem;
//     }

//     .hour-block {
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       padding: 0.5rem 0;
//       border-bottom: 1px solid #ddd;

//       span {
//         flex: 1;
//       }

//       .fa-edit,
//       .fa-trash {
//         margin-right: 1rem;
//         cursor: pointer;
//         color: $primary-color;
//       }

//       .fa-trash {
//         color: $danger;
//       }
//     }

//     .add-button {
//       margin-top: 1rem;
//       background: none;
//       border: none;
//       color: $primary-color;
//       cursor: pointer;
//       display: flex;
//       align-items: center;

//       .fa-plus {
//         margin-right: 0.5rem;
//       }
//     }
//   }

//   .date-picker {
//     margin-top: 1.5rem;

//     .date-picker-title {
//       font-size: 1.25rem;
//       margin-bottom: 0.5rem;
//     }

//     .selection-modes {
//       margin-bottom: 1rem;

//       label {
//         margin-right: 1rem;
//       }
//     }
//   }

//   .availability-list {
//     margin-top: 2rem;

//     .availability-title {
//       font-size: 1.25rem;
//       margin-bottom: 1rem;
//     }

//     .availability-table {
//       width: 100%;
//       border-collapse: collapse;
//       margin-bottom: 1.5rem;

//       th,
//       td {
//         border: 1px solid #ddd;
//         padding: 0.5rem;
//       }

//       th {
//         background-color: #f2f2f2;
//       }
//     }
//     .mb-buttons {
//       margin-bottom: 11.5rem;
//     }
//   }
// }
.schedule {
  display: flex;
  flex-direction: row;
  padding: 20px;
  background-color: #f0f2f5;
  height: 100vh;
}

.column {
  flex: 1;
  padding: 10px;
}

.column-left {
  border-right: 1px solid #ccc;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.column-right {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.service-list,
.professional-list {
  margin-bottom: 20px;
}

.service-list li,
.professional-list li {
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.service-list li:hover,
.professional-list li:hover {
  background-color: #e2e6ea;
}

.service-list li.active,
.professional-list li.active {
  background-color: #007bff;
  color: #fff;
}

.day-button {
  margin: 5px;
}

.add-button {
  margin-top: 10px;
}

.btn-primary,
.btn-success {
  margin-top: 20px;
}

.schedule-title {
  color: #007bff;
}

.hours-title,
.date-picker-title,
.availability-title {
  color: #6c757d;
}

.mb-buttons {
  margin-bottom: 20px;
}
