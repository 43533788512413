@import "../../../assets/styles/variables";

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  .header-logo {
    max-width: 200px;
  }

  @media (max-width: 768px) {
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    .header-logo {
      max-width: 100px;
    }
  }
}
