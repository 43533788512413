/* ProgressBar.scss */
.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.progress {
  height: 10px;
  background-color: #6bcdbf !important;
  transition: width 0.3s ease;
  height: 4px !important;
}
