@import "../../../assets/styles/variables";

.services-section {
  padding: 120px 0 0 0;

  .service {
    &-container {
      height: 100%;
      margin-bottom: 24px;
      padding: 24px;
      border-radius: 12px;
      box-shadow: 0 11px 20px 0 rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h3 {
        font-size: 1.375rem;
        font-weight: 600;
      }
    }
    &-image {
      border-radius: 6px;
      margin-bottom: 24px;
      width: 100%;
      height: auto;
    }
    &-description {
      font-size: 0.875rem;
      margin: 0;
    }
    &-price {
      display: flex;
      gap: 4px;
      font-weight: 500;
      span {
        font-size: 0.875rem;
        font-weight: 400;
      }
      h3 {
        font-size: 1.625rem;
        margin: 0;
      }
    }
    &-button {
      min-width: 240px;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }
    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 991px) {
        align-items: flex-start;
        flex-direction: column;
        gap: 24px;
      }
    }
  }
}
