@import "../../assets/styles/variables";

.admin-index {
  padding: 2rem;

  header {
    text-align: center;
    margin-bottom: 2rem;

    .mb-4 {
      margin-bottom: 1.5rem;
    }
  }

  .summary {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;

    .card {
      background-color: white;
      padding: 1rem;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      text-align: center;
      width: 30%;

      h3 {
        margin-bottom: 0.5rem;
      }

      p {
        margin: 0;
      }

      .text-danger {
        color: #e74c3c;
      }

      .text-success {
        color: #2ecc71;
      }
    }
  }

  .clients {
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    h3 {
      margin-bottom: 1rem;
    }

    .btn {
      margin-bottom: 1rem;
      background-color: $primary-color;
      color: white;
      border: none;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }

    .table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        padding: 0.5rem;
        border: 1px solid #ccc;
      }

      th {
        background-color: #f7fafc;
      }

      td {
        text-align: center;
      }
    }
  }
}
