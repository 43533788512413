@import "../../../assets/styles/variables";

.schedule {
  text-align: center;

  h2 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 2rem;
    color: #666;
  }

  .days {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    .day {
      width: 40px;
      height: 40px;
      margin: 0 0.5rem;
      border-radius: 50%;
      background-color: white;
      border: 1px solid #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background-color 0.3s ease, color 0.3s ease;

      &.selected {
        background-color: $primary-color;
        color: white;
        border-color: $primary-color;
      }

      &:hover {
        background-color: #e0f7fa;
      }
    }
  }

  .time-blocks {
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;

    h3 {
      margin-bottom: 1rem;
    }

    .blocks {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 1rem;

      .block {
        background-color: #ff8a80;
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        margin: 0.5rem;
        position: relative;

        .remove {
          position: absolute;
          top: 0;
          right: 0;
          background: none;
          border: none;
          color: white;
          cursor: pointer;
          font-size: 1rem;
        }
      }
    }

    .edit {
      background: none;
      border: none;
      color: $primary-color;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        text-decoration: underline;
      }
    }

    .add-block-form {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;

      input {
        margin: 0 0.5rem;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }
  }

  .btn-primary {
    background-color: $primary-color;
    border-color: $primary-color;
    font-weight: bold;
    border-radius: 10px;
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
  }

  .btn-primary:hover {
    background-color: darken($primary-color, 10%);
    border-color: darken($primary-color, 10%);
  }
}
