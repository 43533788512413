// src/components/Client/BigCalendarView/BigCalendarView.scss
@import "../../../../../assets/styles/variables";

.big-calendar-view {
  .rbc-calendar {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
  }

  .rbc-toolbar {
    margin-bottom: 1rem;

    .rbc-btn-group button {
      background-color: $primary-color;
      color: white;
      border: none;
      padding: 5px 10px;
      margin-right: 5px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }

  .calendar-container {
    .react-calendar__tile--now {
      background: #ffeb3b;
    }

    .react-calendar__tile--active {
      background: #4caf50;
      color: white;
    }

    .react-calendar__tile--disabled {
      background: #f44336;
      color: white;
      cursor: not-allowed;
    }
  }

  .rbc-event {
    color: black;
    border: 1px solid darken($primary-color, 10%);
    padding: 4px;
    border-radius: 4px;
  }
}
