@import "../../../../assets/styles/variables";

.client-profile {
  .profile-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    label {
      font-weight: bold;
    }

    input {
      width: 60%;
      padding: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }

  .profile-actions {
    margin-top: 1rem;

    .btn {
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &.btn-primary {
        background-color: $primary-color;
        color: white;

        &:hover {
          background-color: darken($primary-color, 10%);
        }
      }

      &.btn-secondary {
        background-color: #ccc;

        &:hover {
          background-color: darken(#ccc, 10%);
        }
      }
    }
  }
}
