.dashboard-index {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .dashboard-content {
    display: flex;
    flex: 1;
    position: relative;

    .sidebar {
      transition: width 0.3s, transform 0.3s;
    }

    .main-content {
      flex: 1;
      padding: 20px;
      transition: margin-left 0.3s, transform 0.3s;
    }
  }

  &.expanded .dashboard-content .sidebar {
    width: 250px;
    transform: translateX(0);
  }

  &.collapsed .dashboard-content .sidebar {
    width: 80px;
    transform: translateX(0);
  }

  &.mobile .dashboard-content {
    .sidebar {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 250px;
      transform: translateX(-100%);
      z-index: 10;
    }

    .main-content {
      margin-left: 0;
      padding: 10px;
      transform: translateX(0);
    }
  }

  &.mobile.expanded .dashboard-content .sidebar {
    transform: translateX(0);
  }

  &.mobile.expanded .dashboard-content .main-content {
    transform: translateX(250px);
  }
}

@media (max-width: 768px) {
  .dashboard-index {
    &.mobile {
      .dashboard-content {
        // flex-direction: column;

        .sidebar {
          width: 80px;
          transform: translateX(0%);
        }

        .main-content {
          margin-left: 0;
          padding: 10px;
        }

        &.expanded .sidebar {
          width: 250px;
          transform: translateX(0);
        }

        &.expanded .main-content {
          transform: translateX(250px);
        }
      }
    }
  }
}
