.sidebar {
  width: 60px;
  transition: width 0.3s ease;
  position: relative;
  &.expanded {
    width: 200px;
  }
  .toggle-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .sidebar-menu {
    list-style: none;
    padding: 0;
    margin: 50px 0 0;
    .menu-item {
      padding: 10px 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &.active {
        background-color: #ddd;
      }
      svg {
        margin-right: 10px;
      }
    }
  }
}

// Estilos responsivos para dispositivos móviles
@media (max-width: 768px) {
  .sidebar {
    width: 60px;
    &.expanded {
      width: 60px;
    }
  }
}
