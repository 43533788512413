@import "../../../assets/styles/variables";

.navbar-user {
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: $header-background-color;
    padding: 24px 0;
}
.logo {
    max-width: 200px;
}

.header-info {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    align-items: center;
    gap: 24px;
    .info-container {
        display: flex;
        align-items: center;
        gap: 8px;
        color: $header-text-color;
        a {
            color: $header-text-color;
            text-decoration: none;
            transition: all ease .3s;
            &:hover {
                opacity: .8;
            }
        }
    }
    .btn-header {
        display: flex;
        align-items: center;
        gap: 8px;
        transition: all ease .3s;
        &:hover {
            opacity: .8;
        }
    }
    @media screen and (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.navbar-toggler {
    border: none;
    background-color: $header-btn-background-color;
    color: $header-btn-text-color;
    padding: 6px 12px;
}

.offcanvas {
    background-color: $header-background-color;
    .custom-close-button {
        color: $header-text-color;
        opacity: 1;
        padding: 12px;
    }
    .offcanvas-header {
        justify-content: space-between;
    }
}