@import "../../../assets/styles/variables";

.account-success {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;

  .success-card {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;

    .success-icon {
      color: $primary-color;
      margin-bottom: 1rem;
    }

    h2 {
      margin-bottom: 1rem;
      color: #333;
    }

    p {
      margin-bottom: 2rem;
      color: #666;
    }

    .btn-primary {
      background-color: $primary-color;
      border-color: $primary-color;
      font-weight: bold;
      border-radius: 10px;
      padding: 0.75rem 1.5rem;
      font-size: 1.25rem;
      width: 100%;
    }

    .btn-primary:hover {
      background-color: darken($primary-color, 10%);
      border-color: darken($primary-color, 10%);
    }
  }
}
