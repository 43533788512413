@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");

$text-color: #000000;
$font-family: "Work Sans", sans-serif;

$border-radius: 100px; //esto sera variable

// Btn primary
$btn-background-color: #000000;
$btn-text-color: #f6c500;

// Header
$header-btn-background-color: #f6c500;
$header-btn-text-color: #000000;
$header-background-color: #000000;
$header-text-color: #ffffff;

// Footer
$footer-background-color: #000000;
$footer-text-color: #ffffff;