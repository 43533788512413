.search-filter {
  .card {
    border: 1px solid #e3e3e3;
    margin-bottom: 30px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

    .card-header {
      background-color: #f7f7f7;
      border-bottom: 1px solid #e3e3e3;
      padding: 15px;
      h4 {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .card-body {
      padding: 15px;

      .filter-widget {
        margin-bottom: 15px;
        .cal-icon {
          height: 44px;
          border: 1px solid #ddd;
          border-radius: 4px;
          width: 100%;
          padding: 5px;
          position: relative;
          input[type="date"] {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 4px;
          }
        }
        h4 {
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 600;
        }
        .custom_check {
          position: relative;
          padding-left: 25px;
          margin-bottom: 10px;
          cursor: pointer;
          font-size: 14px;
          user-select: none;
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            &:checked ~ .checkmark {
              background-color: #2467ca;
              &:after {
                display: block;
              }
            }
          }
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #eee;
            border-radius: 4px;
            &:after {
              content: "";
              position: absolute;
              display: none;
              left: 8px;
              top: 4px;
              width: 5px;
              height: 10px;
              border: solid white;
              border-width: 0 3px 3px 0;
              transform: rotate(45deg);
            }
          }
        }
      }
      .btn-search {
        button {
          background-color: #2467ca;
          color: white;
          border: none;
          padding: 10px 20px;
          border-radius: 4px;
          width: 100%;
          cursor: pointer;
          &:hover {
            background-color: darken(#2467ca, 10%);
          }
        }
      }
    }
  }
}
