@import "../../../../assets/styles/variables";

.block-hours {
  .filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .filter {
      display: flex;
      flex-direction: column;
      margin-right: 1rem;
    }

    label {
      margin-bottom: 0.5rem;
    }

    select,
    input {
      padding: 0.5rem;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }

  button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 0.5rem;

    &.btn-primary {
      background-color: $primary-color;
      color: white;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }

    &.btn-secondary {
      background-color: #ccc;

      &:hover {
        background-color: darken(#ccc, 10%);
      }
    }
  }
}
