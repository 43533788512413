@import "../../../../assets/styles/variables";

.client-dashboard {
  .summary-cards {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;

    .card {
      flex: 1 1 calc(33.333% - 20px);
      padding: 20px;
      margin: 10px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      text-align: center;

      h3 {
        font-size: 1.25rem;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          margin-right: 8px;
          color: $primary-color;
        }
      }

      p {
        font-size: 1rem;
        margin: 5px 0;
      }
    }

    .card-appointments-today {
      background-color: #fff7e6;
      color: #ff9800;
    }

    .card-total-appointments {
      background-color: #e6f3ff;
      color: #2196f3;
    }

    .card-monthly-revenue {
      background-color: #e6f7e6;
      color: #4caf50;
    }
  }

  .appointments-today {
    h3 {
      font-size: 1.5rem;
      margin-bottom: 20px;
    }

    .search-input {
      margin-bottom: 20px;
      padding: 10px;
      width: 100%;
      border: 1px solid #ddd;
      border-radius: 8px;
    }

    .appointments-table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        padding: 12px;
        border-bottom: 1px solid #ddd;
      }

      th {
        background-color: #f8f9fa;
        font-size: 1rem;
      }

      td {
        font-size: 0.875rem;

        .icon {
          margin-right: 8px;
          color: $primary-color;
        }
      }

      .btn-danger {
        background-color: $danger;
        color: #fff;
        padding: 6px 12px;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: $danger-color-dark;
        }
      }
    }

    .pagination {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      button {
        background-color: $primary-color;
        color: #fff;
        border: none;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        &:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }
      }

      input,
      select {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
      }
    }
  }
}

@media (max-width: 768px) {
  .client-dashboard {
    .summary-cards {
      flex-direction: column;

      .card {
        margin: 10px 0;
        flex: 1 1 100%;
      }
    }

    .appointments-today {
      h3 {
        font-size: 1.25rem;
      }
    }
  }
}
