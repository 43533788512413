.map-section {
  padding-top: 120px;
}
.map-box {
  height: 400px !important;
  margin-bottom: 60px;
  iframe {
    width: 100%;
    height: 400px;
    border-radius: 12px;
  }
}

.map-box {
  position: relative;
  height: 400px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  .leaflet-map-container {
    height: 100%;
    width: 100%;
  }
  .leaflet-popup-content-wrapper {
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  .leaflet-popup-tip-container {
    display: none;
  }
  .leaflet-popup-content {
    margin: 8px;
    font-size: 14px;
    color: #333;
  }
  .leaflet-container a.leaflet-popup-close-button {
    display: none;
  }
}
