@import "../../../../assets/styles/variables";

.password-setup {
  text-align: center;

  h2 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 2rem;
    color: #666;
  }

  form {
    .form-group {
      margin-bottom: 1.5rem;
      text-align: left;

      label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: bold;
      }

      input {
        width: 100%;
        padding: 0.75rem;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }

    .error {
      color: red;
      margin-bottom: 1rem;
    }

    .btn-primary {
      background-color: $primary-color;
      border-color: $primary-color;
      font-weight: bold;
      border-radius: 10px;
      padding: 0.75rem 1.5rem;
      font-size: 1.25rem;
    }

    .btn-primary:hover {
      background-color: darken($primary-color, 10%);
      border-color: darken($primary-color, 10%);
    }
  }
}
