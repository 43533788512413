@import "../../../assets/styles/variables";

.form-appointment {
  .footer-container {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    .info-service-container {
      display: flex;
      gap: 24px;
      .info-service {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}
