@import "../../../assets/styles/variables";

.sidebar {
  .list-group {
    .list-group-item {
      display: flex;
      align-items: center;
      padding: 1rem;
      border: none;
      border-bottom: 1px solid #eee;
      background: none;
      cursor: default;

      &.active {
        background-color: $primary-color;
        color: white;

        .step-indicator {
          background-color: darken($primary-color, 10%);
        }
      }

      &.completed {
        cursor: pointer;

        .step-indicator {
          background-color: lighten($primary-color, 30%);
        }
      }

      .step-indicator {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ccc;
        margin-right: 1rem;
      }

      .step-content {
        .step-label {
          font-weight: bold;
        }

        .step-description {
          color: #666;
        }
      }
    }
  }
}
