@import "../../../assets/styles/variables";

.payment-methods {
  text-align: center;

  h2 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 2rem;
    color: #666;
  }

  .method-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;

    .method-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      padding: 0.5rem 0;
      border-bottom: 1px solid #ccc;

      &:last-child {
        border-bottom: none;
      }

      .switch {
        position: relative;
        display: inline-block;
        width: 34px;
        height: 20px;
        margin-right: 1rem;

        input {
          opacity: 0;
          width: 0;
          height: 0;

          &:checked + .slider {
            background-color: $primary-color;

            &:before {
              transform: translateX(14px);
            }
          }
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          transition: 0.4s;
          border-radius: 34px;

          &:before {
            position: absolute;
            content: "";
            height: 14px;
            width: 14px;
            left: 3px;
            bottom: 3px;
            background-color: white;
            transition: 0.4s;
            border-radius: 50%;
          }
        }
      }
    }

    span {
      font-size: 1rem;
      color: #333;
    }
  }

  .btn-primary {
    background-color: $primary-color;
    border-color: $primary-color;
    font-weight: bold;
    border-radius: 10px;
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
  }

  .btn-primary:hover {
    background-color: darken($primary-color, 10%);
    border-color: darken($primary-color, 10%);
  }
}
