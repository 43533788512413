@import "../../../../assets/styles/variables";

.client-login-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.client-login-header {
  background-color: $primary-color;
  padding: 1rem;
  text-align: center;

  .client-login-logo {
    max-width: 150px;
  }
}

.client-login-content {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  .client-login-image {
    flex: 1;
  }

  .client-login-form {
    flex: 1;
    max-width: 400px;
    padding: 2rem;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .form-group {
      margin-bottom: 1rem;
    }

    .btn {
      width: 100%;
      margin-top: 1rem;
    }

    .forgot-password-link {
      display: block;
      text-align: right;
      margin-top: 1rem;
    }

    .social-login {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;

      .btn-facebook {
        background-color: #3b5998;
        color: white;
      }

      .btn-google {
        background-color: #db4437;
        color: white;
      }
    }

    .register-link {
      text-align: center;
      margin-top: 1rem;
    }
  }
}

.client-login-footer {
  background-color: $primary-color;
  text-align: center;
  padding: 1rem;
  color: white;
}

.alert {
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  text-align: center;

  &.alert-danger {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }

  &.alert-success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
}
