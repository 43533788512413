@import "../../../assets/styles/variables";

.footer-user {
  padding: 30px 0;
  background-color: $footer-background-color;
  color: $footer-text-color;
  .footer-about {
    .footer-logo {
      max-width: 150px;
    }
    &-content {
      font-size: .85rem
    }
  }
  .info-container {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    font-size: 0.85rem;
    a {
      text-decoration: none !important;
      transition: all ease .3s;
      color: $footer-text-color;
      &:hover {
        opacity: .8;
      }
    }
  }
  .rrss {
    padding-left: 0;
    padding-bottom: 16px;
    margin-top: 24px;
    li {
      display: inline-block;
      &:last-child i {
        margin-right: 0;
      }
      a {
        margin-right: 16px;
        transition: all ease 0.3s;
        color: $footer-text-color;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
  .copyright {
    border-top: 1px solid hex-to-rgba($body-color, 0.1);
    padding-top: 30px;
    a {
      color: $footer-text-color;
    }
  }
}
