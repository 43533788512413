@import "../../../assets/styles/variables";

.add-client {
  .form-group {
    margin-bottom: 1rem;
  }

  .custom-radio {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    input[type="radio"] {
      appearance: none;
      -webkit-appearance: none;
      background-color: #fff;
      border: 1px solid #ccc;
      padding: 6px;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      cursor: pointer;

      &:checked {
        background-color: $primary-color;
        border-color: $primary-color;
      }

      &:checked::after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    label {
      cursor: pointer;
    }
  }

  .transbank-fields {
    .form-group {
      margin-bottom: 1rem;
    }
  }

  .btn-primary {
    background-color: $primary-color;
    border: none;
    padding: 0.75rem;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }
}
