@import "./assets/styles/variables";
@import "~bootstrap/scss/bootstrap";
// @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

body {
  font-family: $font-family;
  background-color: $white;
}

h1 {
  font-weight: 600;
}
h2 {
  font-weight: 600;
  margin-bottom: 36px;
}
p {
  line-height: 1.3rem;
}

.btn-primary {
  background-color: $btn-background-color;
  color: $btn-text-color;
  padding: 12px 24px;
  transition: all ease .3s;
  &:hover {
    opacity: .8;
    color: $btn-text-color;
  }
}

.btn-secondary {
  background-color: $header-btn-background-color;
  color: $header-btn-text-color;
  transition: all ease .3s;
  padding: 12px 24px;
  &:hover {
    opacity: .8;
    color: $header-btn-text-color;
  }
}

.text-small {
  font-size: 0.75rem;
  line-height: 1rem;
}


// Modals
.modal-header {
  border-bottom: 0;
  align-items: flex-start;
  padding-top: 24px;
  .btn-close {
    margin: 2px;
    opacity: 1;
    transition: all ease .3s;
    &:hover {
      opacity: .6;
    }
  }
  .modal-title {
    font-size: 1.375rem;
    font-weight: 600;
  }
}
.modal-body {
  padding-bottom: 24px;
}

// Forms
.form-label {
  margin-bottom: 0.25rem;
}
.date-selector {
  position: relative;
  .react-datepicker-wrapper {
    width: 100%;
    .form-control {
      padding: 0.375rem 2.375rem 0.375rem 0.75rem
    }
  }
  .calendar-icon {
    position: absolute;
    right: 12px;
    top: 11px;
  }
}
