@import "../../assets/styles/variables";

.onboarding {
  display: flex;

  flex-wrap: wrap;
}

.sidebar {
  width: 200px;
  background-color: #f8f9fa;
  padding: 20px;
}

.onboarding-content {
  flex-grow: 1;
  padding: 20px;
}

.list-group-item {
  cursor: pointer;
}

.list-group-item.active {
  background-color: $primary-color;
  color: white;
}
