@import "../../../assets/styles/variables";

.onboarding-register {
  display: flex;
  height: 100vh;

  .sidebar {
    width: 25%;
    background-color: #00274d !important; // Color de fondo del sidebar
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;

    .logo-container {
      margin-bottom: 2rem;
    }

    .welcome-message {
      text-align: center;

      h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        padding: 60px 5px;
        width: 100%;
      }

      p {
        font-size: 1rem;
        padding: 52px 2px 30px 2px;
      }
    }
  }

  .content {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;

    .form-container {
      width: 100%;
      max-width: 400px;
      padding: 2rem;
      background-color: white;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

      .form-title {
        text-align: center;
        margin-bottom: 2rem;
      }

      .form-group {
        margin-bottom: 1.5rem;
      }

      label {
        font-weight: bold;
      }

      .btn-primary {
        background-color: $primary-color;
        border-color: $primary-color;
      }

      .btn-primary:hover {
        background-color: darken($primary-color, 10%);
        border-color: darken($primary-color, 10%);
      }
    }
  }
}
